<template>
  <div class="deleteProject_wrap">
    <div class="deleteProject_back"></div>
    <div class="left-header">
      <div class="left-header-content">
        <img class="topbar-logo" src="@/assets/logo.png" />
        <div class="head-header">
          <!-- <h1>AutoMind</h1> -->
          <img class="name_logo" src="@/assets/name_logo.png" alt="" />
        </div>
      </div>
    </div>
    <div class="confirm_wrap">
      <div>
        <img
          src="@/assets/img/jurassic.png"
          alt=""
          style="width: 153px; height: 153px"
        />
      </div>
      <div class="confirm_body">
        <p>
          {{ $t("deletProject.DoYouAgree") }}
          <span style="font-weight: bold">{{ info.projectName }}</span>
          {{ $t("deletProject.project") }}？
        </p>
        <p>{{ $t("deletProject.irreversible") }}</p>
      </div>
      <p class="confirm_footer">
        <el-button
          style="width: 172px; height: 62px; font-size: 28px"
          type="primary"
          @click="refuse"
          >{{ $t("deletProject.Deny") }}</el-button
        >
        <el-button
          style="width: 172px; height: 62px; font-size: 28px"
          type="danger"
          @click="agree"
          >{{ $t("deletProject.Agree") }}</el-button
        >
      </p>
    </div>
  </div>
</template>

<script>
import { getProjectProcesses, endProcess } from "@/network/personal/index.js";
export default {
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    getProjectProcesses(this.$route.params.deleteProcessId)
      .then((res) => {
        this.info = res;
        if (res.deleteProcessStatus === "DENIED") {
          this.$message({
            type: "info",
            message: this.$t("deletProject.ProjectDeletionProcessRejected"),
          });
          this.$router.push({ path: `/${this.info.projectId}/home/my_file` });
        } else if (res.deleteProcessStatus === "AGREED") {
          this.$message({
            type: "info",
            message: this.$t("deletProject.ProjectHasBeenDeleted"),
          });
          this.$router.push("/home");
        }
      })
      .catch((err) => {
        this.$router.push("/home");
      });
  },
  methods: {
    refuse() {
      const params = {
        projectId: this.info.projectId,
        deleteProcessId: this.info.deleteProcessId,
        agreed: false,
      };
      endProcess(params).then((res) => {
        this.$router.push(`/${this.info.projectId}/home/my_file`);
      });
    },
    agree() {
      const params = {
        projectId: this.info.projectId,
        deleteProcessId: this.info.deleteProcessId,
        agreed: true,
      };
      endProcess(params).then((res) => {
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.deleteProject_wrap {
  width: 100%;
  height: 100%;
  position: relative;

  .left-header {
    height: 0.3125rem;
    background: linear-gradient(90deg, #004caa 34%, #037abc 100%);

    .left-header-content {
      width: 100%;
      height: 100%;
      display: flex;
      .topbar-logo {
        padding-left: 10px;
        padding-right: 17px;
        padding-top: 7px;
      }
      .topbar-logo img {
        max-height: 100%;
        max-width: 100%;
      }
      .head-header {
        display: flex;
        align-items: center;
        color: #fff;

        .name_logo {
          width: 231px;
          height: 36px;
        }
      }
    }
  }

  .deleteProject_back {
    width: 100%;

    // background-image: url(https://img.js.design/assets/img/624ce99086dcd883eac1c476.png);
    -webkit-filter: blur(25px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .confirm_wrap {
    position: absolute;
    width: 1060px;
    height: 642px;
    border-radius: 20px;
    background: rgba(240, 240, 240, 1);
    left: 50%;
    top: 50%;
    transform: translateX(-530px) translateY(-321px);
    padding: 84px 0 72px 0;
  }
  .confirm_body {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .confirm_footer {
    margin-top: 80px;
    display: flex;
    justify-content: space-around;
    padding: 0 200px;
  }
}
</style>
